import { message } from 'ant-design-vue';
import { Toast } from 'vant';
import axios from 'axios';
import { isMobile } from '.';

export enum IRequestMethod {
    POST = 'post',
    GET = 'get'
}
export interface IRequestOptions {
    url: string;
    method: IRequestMethod;
    data: Iobject;
    headers?: Iobject;
    needAllData?: boolean;
    showTips?: boolean;
}

export function request<T = Iobject>(options: IRequestOptions): Promise<T> {
    return new Promise((resolve: Ifunction) => {
        const { needAllData = false, showTips = true, ...rest } = options;

        axios({
            ...rest,
            baseURL: process.env.VUE_APP_API_HOST,
            withCredentials: true
        })
            .then((res: Iobject) => res.data)
            .then((res: Iobject) => {
                if (needAllData) {
                    resolve(res);
                } else {
                    if (res.respCode > 10000 && showTips) {
                        if (isMobile) {
                            Toast.fail({
                                message: res.msg
                            });
                        } else {
                            message.error(res.msg);
                        }
                    }

                    resolve(res.data);
                }
            });
    });
}
