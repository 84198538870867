import { IRequestMethod, request } from '@/utils/request';
import { compileStr, setTimeoutSync } from '@mobro/libs';
import { Ifunction, Iobject } from '@mobro/libs/es/type';
import { message } from 'ant-design-vue';
import { getCurrentInstance, reactive, ref } from 'vue';
import { useRoute } from 'vue-router';

export interface LoginFormInfo {
    account: string;
    password: string;
    remember: boolean;
}

type ErrorInfo = {
    msg: string;
    code: number;
} | null;

export function useLogin(config: { redirect?: boolean } = {}) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { appContext } = getCurrentInstance() as any;
    const { redirect = true } = config;

    const errorInfo = ref<ErrorInfo>(null);
    const userInfo = ref({});
    const { query } = useRoute();

    /** 校验表单 */
    const check = (formInfo: LoginFormInfo): ErrorInfo => {
        const { account, password } = formInfo;
        if (!account) {
            errorInfo.value = { msg: '账号不可为空', code: 1 };
        } else if (!password) {
            errorInfo.value = { msg: '密码不可为空', code: 1 };
        }
        errorInfo.value = null;
        return errorInfo.value;
    };

    /** 登录成功后跳转 */
    async function redirectTo() {
        await setTimeoutSync(800);

        if (query?.redirect) {
            window.location.replace(decodeURIComponent(query.redirect as string));
        }
    }

    /** 登录逻辑 */
    const login = async (formInfo: LoginFormInfo): Promise<{ status: boolean; userInfo: Iobject }> => {
        message.loading({ content: '登录中...', key: 'login' });
        return new Promise((resolve: Ifunction) => {
            const isPhone = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/g.test(
                formInfo.account
            );
            request({
                url: '/api/auth/login',
                method: IRequestMethod.POST,
                data: isPhone
                    ? { phone: formInfo.account, password: compileStr(formInfo.password), refer: query?.redirect }
                    : { uuid: formInfo.account, password: compileStr(formInfo.password), refer: query?.redirect }
            })
                .then((res: Iobject) => {
                    message.success({ content: '登录成功，正在跳转中...', key: 'login', duration: 1 });

                    userInfo.value = res;
                    // formInfo.remember && setLocalStorage('USER_INFO', res, 7);
                    redirect && redirectTo();

                    resolve({
                        status: true,
                        userInfo: userInfo.value
                    });
                })
                .finally(() => {
                    resolve({ status: false, userInfo: {} });
                });
        });
    };

    /** ******************** 表单 start ********************** */

    let loginFormData: LoginFormInfo = reactive({
        account: '',
        password: '',
        remember: true
    });

    function editLoginFormData(data: LoginFormInfo) {
        loginFormData = {
            ...loginFormData,
            ...data
        };
    }
    /** ******************** 表单 end ********************** */

    return {
        check,
        login,
        errorInfo: errorInfo.value,
        userInfo: userInfo.value,
        loginFormData, // 表单数据
        editLoginFormData // 修改表单数据
    };
}
