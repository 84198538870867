import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import PC from '@/views/LoginPc.vue';
import PageTest from '@/views/PageTest.vue';
import TestPage from '@/views/TestPage.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/pc'
    },
    {
        path: '/pc',
        name: 'pc',
        meta: {
            description: 'pc description',
            title: 'pc login'
        },
        component: PC
    },
    {
        path: '/pagetest',
        name: 'pagetest',
        meta: {
            description: 'pagetest description',
            title: 'pagetest login'
        },
        component: PageTest
    },
    {
        path: '/testpage',
        name: 'testpage',
        meta: {
            description: 'testpage description',
            title: 'testpage login'
        },
        component: () => import('@/views/TestPage.vue')
    },
    {
        path: '/pc',
        name: 'pc',
        meta: {
            description: 'pc description',
            title: 'pc login'
        },
        component: PC
    },
    {
        path: '/phone',
        meta: {
            description: '我是移动端的描述哈',
            title: '移动端登录'
        },
        name: 'phone',
        // route level code-splitting
        // this generates a separate chunk (phone.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "phone" */ '@/views/LoginPhone.vue')
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    if (to?.meta?.description) {
        let metaDesp = document.querySelector('meta[name="description"]');
        if (!metaDesp) {
            metaDesp = document.createElement('meta');
            metaDesp.setAttribute('name', 'description');
            metaDesp.setAttribute('content', to.meta.description as string);
            document.head.appendChild(metaDesp);
        } else {
            metaDesp.setAttribute('content', to.meta.description as string);
        }
    }
    if (to?.meta?.title) {
        document.title = to.meta.title as string;
    }

    next();
});

export default router;
