import 'ant-design-vue/dist/antd.css';
import { Button, Checkbox, Form, Input, message } from 'ant-design-vue';

export const pc = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    install(vue: any) {
        vue.config.globalProperties.$pcmessage = message;
        vue.use(Form).use(Button).use(Input).use(Checkbox);
    }
};
