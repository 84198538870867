
import { defineComponent, reactive } from 'vue';
import { LoginFormInfo, useLogin } from '@/composables/login';
import { Ifunction, Iobject } from '@mobro/libs/lib/type';

interface LoginFormRules {
    account: Iobject[];
    password: Iobject[];
}

export default defineComponent({
    name: 'PC',
    components: {
        // HelloWorld
    },
    setup(props, context) {
        const { loginFormData, login } = useLogin();

        const onFinish = async (values: LoginFormInfo) => {
            await login(values);
        };

        const onFinishFailed = (errorInfo: Iobject) => {
            console.log('Failed:', errorInfo);
        };

        function validatePassword(rule: Iobject, value: string, callback: Ifunction) {
            if (value === '') {
                callback(new Error('密码不可为空'));
            } else if (value.length < 8) {
                callback(new Error('密码长度不能小于8位'));
            }
            callback();
        }

        function validateAccount(rule: Iobject, value: string, callback: Ifunction) {
            if (value === '') {
                callback(new Error('账号不可为空'));
            } else if (!/\w{4,}/g.exec(value)) {
                callback(new Error('账号格式不正确'));
            } else {
                callback();
            }
        }

        const loginFormRules: LoginFormRules = reactive({
            password: [{ validator: validatePassword, trigger: 'blur' }],
            account: [{ validator: validateAccount, trigger: 'blur' }]
        });

        return {
            loginFormData,
            loginFormRules,
            onFinish,
            onFinishFailed
        };
    }
});
