export function calcPath() {
    const { pathname, search, origin } = window.location;

    if (isMobile && pathname === '/pc') {
        window.location.replace(`${origin}/phone${search}`);
    } else if (!isMobile && pathname === '/phone') {
        window.location.replace(`${origin}/pc${search}`);
    }
}

export const isMobile = /Android|iPhone|iPad|iPod|BlackBerry|webOS|Windows Phone|SymbianOS|IEMobile|Opera Mini/i.test(
    navigator.userAgent
);
