
import { defineComponent, reactive } from 'vue';
import { LoginFormInfo, useLogin } from '@/composables/login';
import { Ifunction, Iobject } from '@mobro/libs/lib/type';

interface LoginFormRules {
    account: Iobject[];
    password: Iobject[];
}

export default defineComponent({
    name: 'PageTest',
    components: {
        // HelloWorld
    }
});
